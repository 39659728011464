<template>
  <v-card
    color="element"
    class="rounded-lg"
  >
    <v-card-title class="pt-2">
      {{ $t('newDeals.productInfo.firstCard.secondTitle') }}
      <v-chip small class="ml-2" color="backgroundLight">
        {{ $t('newDeals.productInfo.firstCard.titleChip') }}
      </v-chip>
    </v-card-title>
    <v-card-text class="defaultText--text">
      <v-row>
        <v-col class="pb-0">
          {{ $t('newDeals.productInfo.firstCard.fourthItem') }}
        </v-col>
        <v-col align="right" class="pb-0">
          {{ $n(totalAmount, 'float') }} €
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          {{ $t('newDeals.productInfo.firstCard.fifthItem') }}
        </v-col>
        <v-col align="right">
          {{ $n(investorSubscriptionInformation.subscribedTokens) }} Token
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'SubscriptionInvestmentCard',
  props: {
    investorSubscriptionInformation: {
      type: Object,
      required: true,
    },
  },
  computed: {
    totalAmount() {
      const { subscribedTokens, tokenPrice } = this.investorSubscriptionInformation;
      return subscribedTokens * tokenPrice;
    },
  },
};
</script>

<style>

</style>
